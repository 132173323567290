import Cookie from "js-cookie";
import get from "lodash/get";
import { ClueProps } from "src/pages/Play/Clue/types";

export const isLoggedIn = () => {
  const token = Cookie.get("token");
  return !!token;
};

export const getFormattedNumber = (value: {
  currentTarget: { value: string };
}) => {
  const x = value.currentTarget.value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return x && !x[2] ? x[1] : x && x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
};

export const getDefaultFormattedNumber = (value: string) => {
  const x = value?.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return x && !x[2] ? x[1] : x && x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
};

export const passwordValidator = {
  length: /^.{10,}$/,
  letter: /[a-zA-Z]/,
  numberOrSpecial: /[\d!@#$%^&*(),.?":{}|<>]/,
};

export const currentYear = new Date().getFullYear();

export const milesToMeters = (miles: number) => {
  return miles * 1609.34;
};

export const getUserAgentData = () => {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const deviceName =
    get(navigator, "userAgentData.platform", null) || navigator.platform;

  return {
    deviceName,
    isMobile: regex.test(navigator.userAgent),
    type: regex.test(navigator.userAgent) ? "Mobile" : "Desktop",
  };
};

export const isClueCompleted = (clue: ClueProps) => {
  if (clue.answer_status === "locked") {
    return false;
  }
  const isAllAnswersGiven =
    clue?.answers?.length > 0 && clue?.answers?.every((ans) => !!ans?.answer);
  const allIncorrectAnswers = clue?.answers?.filter(
    (ans) => ans?.answer?.is_correct === 0
  );
  const isCustom = clue.field_type.slug === "custom";

  const checkForAnswerCount =
    clue?.answer_count === 0 ? (isCustom ? true : false) : true;

  const isCompleted =
    checkForAnswerCount &&
    isAllAnswersGiven &&
    allIncorrectAnswers.length === 0;
  return isCompleted;
};

export const checkAllFreeCluesCompleted = (updatedClues: ClueProps[]) => {
  const freeClues = updatedClues.filter(
    (clue) => clue.answer_status !== "locked"
  );
  return freeClues.every(
    (clue: ClueProps) =>
      clue.answer_status === "complete" || isClueCompleted(clue)
  );
};
