import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback, useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { DISABLED_RECAPTCHA } from "src/helpers/constants";
import { addSignupDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { passwordValidator } from "src/utils/utils";
import classes from "./styles.module.scss";
import { CreatePasswordInputProps, PasswordValidator } from "./types";

const passwordValidationConstant: PasswordValidator[] = [
  { key: "length", message: "At least 10 characters" },
  { key: "letter", message: "At least one letter" },
  {
    key: "numberOrSpecial",
    message: "At least one number or special character",
  },
];

const disabledRecaptcha = DISABLED_RECAPTCHA;
const siteKey = process.env.REACT_APP_RECAPTCHA_KEY || "";

const CreatePassword = () => {
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState();

  const navigate = useNavigate();
  const actions = useStoreActions({
    addSignupDetails,
  });
  const [searchParams] = useSearchParams();
  // const isFreeTrial = searchParams.get("free_trial") === "true";
  const signupDetail = useReducerData("auth", "signupDetails", {});

  const signUpPassWordSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(passwordValidator.length, "At least 10 characters")
      .matches(passwordValidator.letter, "At least one letter")
      .matches(
        passwordValidator.numberOrSpecial,
        "At least one number or special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Password does not match"),
  });

  const onVerify = useCallback((token: any) => {
    setToken(token);
  }, []);

  const getIcon = (validated: boolean | null) => {
    if (validated) {
      return <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />;
    } else {
      return (
        <FontAwesomeIcon
          className={classes.period}
          size="2xs"
          icon={faCircle}
        />
      );
    }
  };

  const handleSubmit = (
    values: CreatePasswordInputProps,
    formikHelpers: FormikHelpers<CreatePasswordInputProps>
  ) => {
    const signupData = {
      password: values?.password,
      password_confirmation: values?.confirmPassword,
      recaptcha_token: token,
    };
    actions.addSignupDetails({ ...signupDetail, ...signupData });
    navigate({
      pathname: `/auth/create-username`,
      search: `?${createSearchParams(searchParams)}`,
    });
    formikHelpers.resetForm();
    setRefreshReCaptcha(!refreshReCaptcha);
  };

  return (
    <div className={classes.container}>
      <Formik
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers);
        }}
        validateOnMount
        validationSchema={signUpPassWordSchema}
        initialValues={{
          password: signupDetail?.password || "",
          confirmPassword: signupDetail?.password_confirmation || "",
          recaptcha: "",
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
        }: FormikProps<CreatePasswordInputProps>) => {
          return (
            <Form className={cx(classes.signupInputs)}>
              <FormGroup
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => {
                  if (!errors.password) {
                    setPasswordFocus(false);
                  }
                }}
                className={classes.formGroup}
              >
                <Input
                  labelClassName={classes.label}
                  inputGroupClassName={classes.inputWrapper}
                  inputClassName={classes.input}
                  showPasswordIcon
                  label="Password"
                  value={values.password}
                  placeholder="At least 10 characters"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.password}
                  name="password"
                  type="password"
                  required
                />
                {passwordFocus && (
                  <div className={classes.passwordValidations}>
                    {passwordValidationConstant.map((data, i) => {
                      const isValid = passwordValidator[data.key].test(
                        values.password
                      );
                      return (
                        <div
                          key={i}
                          className={cx(classes.message, {
                            [classes.invalid]:
                              (!isValid && values.password !== "") ||
                              values.password === "",
                          })}
                        >
                          <div className={classes.iconWrapper}>
                            {getIcon(isValid)}
                          </div>
                          <Typography className="mb-0" variant="p16">
                            {data.message}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                )}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Input
                  labelClassName={classes.label}
                  inputGroupClassName={classes.inputWrapper}
                  inputClassName={classes.input}
                  showPasswordIcon
                  label="Confirm Password"
                  value={values.confirmPassword}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.confirmPassword}
                  name="confirmPassword"
                  type="password"
                  required
                />
                {values.confirmPassword && errors.confirmPassword && (
                  <Typography
                    className={classes.passwordNotMatched}
                    variant="p16"
                  >
                    <FontAwesomeIcon
                      className={classes.period}
                      size="2xs"
                      icon={faCircle}
                    />
                    Password does not match
                  </Typography>
                )}
                {values.confirmPassword && !errors.confirmPassword && (
                  <Typography className={classes.passwordMatched} variant="p16">
                    <FontAwesomeIcon
                      className={classes.checkIcon}
                      icon={faCheck}
                    />
                    Password matches!
                  </Typography>
                )}
              </FormGroup>
              <div className={classes.recaptcha}>
                {!disabledRecaptcha && (
                  <GoogleReCaptchaProvider
                    reCaptchaKey={siteKey}
                    useEnterprise={true}
                    scriptProps={{
                      async: true,
                      defer: true,
                      appendTo: "head",
                    }}
                  >
                    <GoogleReCaptcha
                      action="signup"
                      onVerify={onVerify}
                      refreshReCaptcha={refreshReCaptcha}
                    />
                  </GoogleReCaptchaProvider>
                )}
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  buttonText="Next"
                  buttonColor="purple-gradient"
                  variant="fill"
                  type="submit"
                  disabled={!isValid}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className={classes.tAndC}>
        Already have an account?
        <span
          onClick={() => {
            navigate({
              pathname: `/auth/login`,
              search: `?${createSearchParams(searchParams)}`,
            });
          }}
        >
          Sign in
        </span>
      </div>
    </div>
  );
};

export default CreatePassword;
